<template>
  <component
    :is="tag"
    :class="computedClasses"
  >
    <slot />
  </component>
</template>

<script setup lang="ts">
export interface Props {
  tag?: string
  inline?: boolean
  column?: boolean
  reverse?: boolean
  wrap?: boolean
  noWrap?: boolean
  grow?: boolean
  justify?: string
  align?: string
  alignH?: string
  alignV?: string
  wrapReverse?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  tag: 'div',
  inline: false,
  column: false,
  reverse: false,
  wrap: false,
  noWrap: false,
  wrapReverse: false,
  grow: false,
  justify: null,
  align: null,
  alignH: null,
  alignV: null,
})

const computedClasses = computed(() => {
  const componentData = { class: [] }
  const classObj = {}
  let hAxis = 'justify-content'
  let hProp = 'justify'
  let vAxis = 'align-items'
  let vProp = 'align'
  if (props.column) {
    hAxis = 'align-items'
    hProp = 'align'
    vAxis = 'justify-content'
    vProp = 'justify'
  }
  componentData.class.push(`flex${props.inline ? '--inline' : ''}`)
  componentData.class.push(`flex-dir--${props.column ? 'column' : 'row'}${props.reverse ? '-reverse' : ''}`)
  componentData.class.push(classObj)
  classObj[`flex-wrap${props.wrapReverse ? '-reverse' : ''}`] = !props.noWrap
  classObj['flex-nowrap'] = props.noWrap
  classObj['grow-children'] = props.grow
  classObj[`justify-content-${props.justify}`] = props.justify
  classObj[`align-items-${props.align}`] = props.align
  classObj[`${hAxis}-${props.alignH}`] = props.alignH && !props[hProp]
  classObj[`${vAxis}-${props.alignV}`] = props.alignV && !props[vProp]

  return componentData.class
})
</script>

<style>
.flex {
  display: flex;
}

.flex--inline {
  display: inline-flex;
}

.flex-dir--row {
  flex-direction: row;
}

.flex-dir--column {
  flex-direction: column;
}

.flex-dir--row-reverse {
  flex-direction: row-reverse;
}

.flex-dir--column-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
}

.justify-content-evenly {
  justify-content: space-evenly;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-content-between {
  align-content: space-between;
}

.align-content-around {
  align-content: space-around;
}

.align-content-stretch {
  align-content: stretch;
}

.align-self-auto {
  align-self: auto;
}

.align-self-start {
  align-self: flex-start;
}

.align-self-end {
  align-self: flex-end;
}

.align-self-center {
  align-self: center;
}

.align-self-baseline {
  align-self: baseline;
}

.align-self-stretch {
  align-self: stretch;
}

.grow-children > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}
</style>
